
  import {useRoute, useRouter} from "vue-router";
  import {defineComponent, reactive, toRefs} from "vue";
  import {ElMessage} from "element-plus/es";
  import useMarkdownLinkCopy from "@/composables/useMarkdownLinkCopy";
  import {MARKDOWN_ENGINE_VDITOR} from "@/constants/note";
  import DefaultRenderingSelection from "@/components/notes/DefaultRenderingSelection.vue";
  import ShareDialog from "@/components/notes/ShareDialog.vue";
  import {FILE_TYPE_NOTE} from "@/constants/file-type";

  export default defineComponent({
    name: "NoteViewHeader",
    emits: ['onEditClick'],
    components: {
      DefaultRenderingSelection,
      ShareDialog
    },
    props: {
      noteId: Number,
      name: String,
      engineType: Number,
      renderType: Number,
      defaultRendering: Number,
      renderTypeChanged: Function,
      defaultRenderingChanged: Function,
      inBrowseMode: Boolean,
      hideTitle: Boolean,
      shared: Boolean
    },
    setup(props, ctx) {
      const router = useRouter();
      const route = useRoute();
      const {copyMarkdownLink} = useMarkdownLinkCopy();

      const state = reactive({
        renderingDialogVisible: false,
        shareDialogVisible: false
      });

      const goBack = () => {
        router.back();
      }

      const edit = () => {
        if (props.inBrowseMode) {
          ctx.emit('onEditClick')
        } else {
          const name = props.engineType == MARKDOWN_ENGINE_VDITOR ? 'editNoteByVditor' : 'editNote';
          router.push({name: name, params: {noteId: props.noteId}});
        }
      }

      const histories = () => {
        router.push({name: 'noteHistoriesMobile', params: {noteId: props.noteId}});
      }

      const mindMap = () => {
        renderTypeChanged(2)
      }

      const markdown = () => {
        renderTypeChanged(1)
      }

      const renderTypeChanged = (type: number) => {
        props.renderTypeChanged && props.renderTypeChanged(type)
      }

      const copyNoteLink = () => {
        const result = copyMarkdownLink({
          fileType: FILE_TYPE_NOTE,
          id: props.noteId,
          name: props.name,
          engine: props.engineType
        }, props.inBrowseMode);

        if (result) {
          ElMessage({
            showClose: true,
            message: '复制成功',
            type: 'success'
          });
        }
      }

      const changeDefaultRendering = () => {
        state.renderingDialogVisible = true
      }

      const changeDefaultRenderingSuccess = (renderingValue: number) => {
        props.defaultRenderingChanged && props.defaultRenderingChanged(renderingValue);
        state.renderingDialogVisible = false
      }

      const changeDefaultRenderingCancel = () => {
        state.renderingDialogVisible = false
      }

      const share = () => {
        state.shareDialogVisible = true
      }

      const closeShareDialog = () => {
        state.shareDialogVisible = false
      }

      const shortName = () => {
        if (props.hideTitle) {
          return "显示"
        }
        if (props.name == null) {
          return ""
        }
        if (props.name.length <= 32) {
          return props.name
        }
        return props.name.substring(0, 32) + "..."
      }

      return {
        ...toRefs(state),
        goBack,
        edit,
        histories,
        copyNoteLink,
        mindMap,
        markdown,
        changeDefaultRendering,
        changeDefaultRenderingSuccess,
        changeDefaultRenderingCancel,
        share,
        closeShareDialog,
        shortName
      }
    },
  })
